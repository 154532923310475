import React from "react";
import { CopySection, NextArrow } from "@molecules";
import { Container, Icon, Text } from "@atoms";
import loadable from "@loadable/component";

const Stats = loadable(() => import("@organisms/Stats"));
const Results = ({ heading, copy, mapHeading, stats }) => {
  return (
    <Container>
      <CopySection heading={heading} copy={copy} inverse />
      <div className="mt-10 flex flex-col sm:flex-row">
        <NextArrow />
      </div>
      <div className="py-[10vh]">
        <Text variant="h2">{mapHeading}</Text>
        <div className="py-[10vh]">
          <Icon name="world" className="w-full text-offwhite" />
        </div>
        <Stats stats={stats} />
      </div>
    </Container>
  );
};

export default Results;
